.p-site-banner {
    width: 100%;
    height: 165px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    @include mq-down(md) {
        height: 105px;
    }
    &__text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color: $cl-text;
        font-size: 32px;
        width: 100%;
        text-align: center;
        letter-spacing: 0.06em;
        @extend %font-en;
        @include mq-down(md) {
            font-size: 24px;
            letter-spacing: 0.12em;
        }
        span {
            font-size: 18px;
            display: block;
            text-align: center;
            letter-spacing: 0.2em;
            font-weight: 400;
            color: #5F5F5F;
            @extend %font-en;
            @include mq-down(md) {
                font-size: 14px;
            }
        }
    }
}
