.widget_categories {
    .widget-title {
        font-weight: normal;
    }
    ul {
        li {
            margin-bottom: 0;
            border: 1px solid #E5E8EB;
            transition: 0.2s;
            &:not(:first-of-type) {
                border-top: none;
            }
            a {
                display: block;
                padding: 15px 25px;
            }
            &:hover {
                background: #E5E8EB;
            }
        }
    }
}
