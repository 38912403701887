.p-post-news {
    display: flex;
    justify-content: space-between;
    @include mq-down(md) {
        display: block;
    }
    .post-date {
        display: block;
        letter-spacing: 0.08em;
        width: 120px;
        @include mq-down(md) {
            font-size: 14px;
        }
    }
    .post-title {
        width: calc(100% - 130px);
        @include mq-down(md) {
            width: 100%;
            font-size: 14px;
            line-height: 1.71;
        }
    }
    &:hover {
        color: $cl-primary;
    }
}
