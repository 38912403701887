.p-heading-1 {
    color: $cl-text;
    letter-spacing: 0.06em;
    font-size: 32px;
    font-weight: bold;
    @include mq-down(md) {
        font-size: 23px;
    }
}

.p-heading-2 {
    font-size: 26px;
    background: $cl-primary;
    color: #fff;
    border-radius: 5px;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 20px 30px;
    font-weight: 500;
    @include mq-down(md) {
        font-size: 20px;
        padding: 15px 20px;
    }
}

.p-heading-3 {
    font-size: 22px;
    color: $cl-primary;
    letter-spacing: 0.02em;
    border: none;
    padding: 0;
    font-weight: 500;
    @include mq-down(md) {
        font-size: 20px;
    }
}

.p-ul {
    margin-top: 20px;
    margin-bottom: 30px;
    li {
        font-size: 18px;
        margin-bottom: 15px;
        padding: 0 0 0 25px;
        position: relative;
        letter-spacing: 0.08em;
        line-height: 1.67;
        @include mq-down(md) {
            font-size: 16px;
            line-height: 1.5;
        }
        &:before {
            position: absolute;
            content: '●';
            color: #B8D9D5;
            font-size: 10px;
            left: 0;
            top: 10px;
        }
    }
}

.p-ol {
    margin-top: 20px;
    margin-bottom: 30px;
    counter-reset: item;
    li {
        font-size: 18px;
        margin-bottom: 15px;
        padding: 0 0 0 25px;
        position: relative;
        letter-spacing: 0.08em;
        line-height: 1.67;
        &:before {
            position: absolute;
            counter-increment: item;
            content: counter(item);
            color: $cl-primary;
            left: 0;
            top: 3px;
        }
    }
}
