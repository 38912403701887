.pagination {
    margin: 50px 0 80px;
    @include mq-down(md) {
        margin: 40px 0;
    }
    .nav-links {
        display: flex;
        justify-content: center;
        .page-numbers {
            width: 40px;
            height: 40px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
            font-weight: bold;
            border: none;
            &:hover {
                color: $cl-primary;
                background: none;
            }
            &.dots {
                color: #3B3D46;
            }
            &.current {
                color: #fff;
                background: #B8D9D5;
                border-radius: 50%;
                &:hover {
                    background: #B8D9D5;
                }
            }
        }
        .next, .prev {
            display: none;
        }
    }
}
