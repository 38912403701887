.sidebar-area {
    border-top: none;
}

.single {
    .post-thumbnail {
        margin-left: 0;
        margin-right: 0;
    }
}

