.c-columns-4 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > * {
        width: 24%;
        margin-bottom: 20px;
        @include mq-down(md) {
            width: 100% !important;
        }
    }
    &:before {
        content: '';
        width: 24%;
        order:1;
    }
    &:after {
        content: '';
        width: 24%;
    }
}
