.c-post-sm {
    display: flex;
    border: 1px solid #E5E8EB;
    border-radius: 6px;
    height: 135px;
    overflow: hidden;
    transition: 0.2s;
    &:hover {
        background: #E6EDF4;
    }
    &__thumb {
        width: 35%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__content {
        width: 65%;
        padding: 5px 15px;
        position: relative;
    }
    &__title {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
        @include line-clamp(3);
    }
    &__meta {
        position: absolute;
        width: calc(100% - 30px);
        top: 100px;
        @include mq-down(sm) {
            top: 105px;
        }
    }
    .tag-item {
        font-size: 11px;
    }
}
