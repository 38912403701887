body.home {
    padding-top: 0;
}

.home, .page-template-lp {
    .l-header {
        transition: 0.5s;
        .siteTitle {
            &__main {
                svg {
                    path {
                        transition: 0.5s;
                    }
                }
            }
            &__sub {
                transition: 0.5s;
            }
        }
        &:not(.active) {
            background: none;
            .siteTitle {
                &__main {
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
                &__sub {
                    color: #fff;
                }
            }
            .menuNavi {
                display: none;
            }
            .c-menu {
                &__line {
                    background: #fff;
                    &:before {
                        background: #fff;
                    }
                    &:after {
                        background: #fff;
                    }
                }
            }
        }
    }
}

.home {
    .l-extra {
        display: none;
    }
}

.top-container {
    background: #fff;
    overflow: hidden;
    .container {
        width: 1200px;
        @include mq-down(md) {
            width: 100%;
        }
    }
    h2 {
        @extend .p-heading-3;
        font-size: 24px;
        font-weight: 500;
        span {
            font-size: 18px;
            display: inline-block;
            margin-left: 20px;
            @include mq-down(md) {
                font-size: 15px;
                margin: 5px 0 0;
                display: block;
            }
        }
    }
    .main-visual {
        position: relative;
        .main-slider {
            li {
                padding-top: 50%;
                background-size: cover;
                background-position: center;
                position: relative;
                @include mq-down(md) {
                    padding-top: 100%;
                }
                @include mq-down(sm) {
                    padding-top: 140%;
                }
                .slider-content {
                    position: absolute;
                    width: 90%;
                    @include mq-down(md) {
                        top: auto !important;
                        bottom: 5%;
                        transform: none !important;
                    }
                    &--center {
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        @include mq-down(md) {
                            transform: translateX(-50%) !important;
                        }
                    }
                    &--left {
                        left: 10%;
                        top: 50%;
                        transform: translateY(-50%);
                        text-align: left;
                        @include mq-down(md) {
                            left: 5%;
                        }
                    }
                    &--right {
                        right: 10%;
                        top: 50%;
                        transform: translateY(-50%);
                        text-align: right;
                        @include mq-down(md) {
                            right: 5%;
                        }
                    }
                    &__text {
                        color: #fff;
                        font-size: 32px;
                        font-weight: 500;
                        letter-spacing: 0.13em;
                        margin-bottom: 20px;
                        @include mq-down(md) {
                            font-size: 21px;
                        }
                    }
                    .c-btn {
                        letter-spacing: 0.12em;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        font-weight: 500;
                        width: 250px;
                        min-width: auto;
                        @include mq-down(md) {
                            width: 250px;
                        }
                    }
                }
            }
        }
        .swiper-button-next, .swiper-button-prev {
            color: #fff;
            &:after {
                font-size: 30px;
            }
        }
    }


    .cat-slider {
        margin: 20px 0 100px;
        width: 100%;
        @include mq-down(md) {
            margin-bottom: 60px;
        }
        .item {
            font-size: 14px;
            width: 200px;
            padding: 0 10px;
            text-align: center;
            transition: 0.2s;
            @include mq-down(md) {
                width: 150px;
                font-size: 12px;
                padding: 0;
            }
            img {
                width: 225px;
                height: 170px;
                object-fit: contain;
                -webkit-user-drag: none;
                -moz-user-select: none;
                @include mq-down(md) {
                    height: 150px;
                }
            }
            span {
                display: block;
                font-weight: 500;
            }
            &:hover {
                opacity: 0.6;
            }
        }
        .swiper-wrapper {
            transition-timing-function: linear;
        }
    }

    .sp-slider {
        .swiper-wrapper {
            justify-content: space-between;
            .swiper-slide {
                width: 32%;
                @include mq-down(md) {
                    width: 100%;
                }
            }
        }
    }

    .sec-1 {
        display: flex;
        justify-content: space-between;
        @include mq-down(md) {
            display: block;
        }
        h2 {
            font-size: 20px;
        }
        .pickups {
            width: 65%;
            @include mq-down(md) {
                width: 100%;
                margin-bottom: 50px;
            }
        }
        .latest {
            width: 30%;
            @include mq-down(md) {
                width: 100%;
            }
            a {
                transition: 0.2s;
                display: block;
                font-size: 15px;
                padding: 5px 0;
                border-bottom: 1px solid #CCC;
                margin-bottom: 5px;
                @include mq-down(md) {
                    padding: 10px 0;
                }
                .post-title {
                    font-weight: 500;
                    margin-bottom: 5px;
                    @include mq-down(md) {
                        margin-bottom: 10px;
                        line-height: 1.6;
                    }
                }
                span {
                    font-size: 12px;
                }
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
    .sec-2 {
        .single-bottom-banner {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            & > * {
                width: 49%;
                @include mq-down(md) {
                    width: 100%;
                }
            }
        }
    }
    .sec-3 {
        .c-columns-3 {
            @include mq-down(md) {
                flex-wrap: initial;
            }
        }
    }
    .sec-4 {
        .c-full-width {
            background: #F5F8FB;
            padding: 80px 0;
            @include mq-down(md) {
                padding: 50px 0;
            }
            .c-columns-3 {
                @include mq-down(lg) {
                    & > * {
                        width: 48%;
                    }
                }
            }
            .nav {
                margin-bottom: 50px;
                &__title {
                    background: $cl-primary;
                    color: #fff;
                    text-align: center;
                    font-size: 21px;
                    font-weight: 500;
                    border-radius: 6px;
                    letter-spacing: 0.08em;
                    padding: 20px 5px;
                    @include mq-down(md) {
                        font-size: 18px;
                        padding: 15px 5px;
                    }
                }
                &__list {
                    padding: 0 20px;
                    @include mq-down(md) {
                        padding: 0;
                    }
                }
                &__item {
                    a {
                        font-weight: 500;
                        letter-spacing: 0.13em;
                        padding: 15px 10px;
                        display: block;
                        border-bottom: 1px solid $cl-primary;
                        position: relative;
                        transition: 0.2s;
                        @include mq-down(md) {
                            font-size: 15px;
                            font-weight: 500;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 15px;
                            height: 15px;
                            background: url($image-dir + 'arrow-primary.svg') no-repeat center;
                            background-size: contain;
                            @include mq-down(md) {
                                right: 5%;
                            }
                        }
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }
    .sec-5 {
        .p-cat-post-list {
            margin-bottom: 60px;
        }
        .c-columns-4 {
            @include mq-down(md) {
                flex-wrap: initial;
            }
        }
    }
    .sec-6 {
        h2 {
            font-size: 20px;
        }
        &.c-columns-3 {
            @include mq-down(lg) {
                & > * {
                    width: 48%;
                }
            }
        }
        .p-cat-post-list-sm {
            margin-bottom: 60px;
            @include mq-down(md) {
                margin-bottom: 50px;
            }
        }
        .c-post-sm {
            margin-bottom: 20px;
        }
    }

    @include mq-down(md) {
        .post-slider {
            .swiper-slide {
                width: 80% !important;
            }
        }
    }
    @include mq-down(sm) {
        .post-slider {
            padding-left: 20px;
            width: calc(100vw);
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: calc(-50vw);
            margin-right: calc(-50vw);
        }
    }
}
