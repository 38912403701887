.c-post {
    border-radius: 6px;
    border: 1px solid #E5E8EB;
    display: block;
    transition: 0.2s;
    overflow: hidden;
    &:hover {
        background: #E6EDF4;
    }
    &__thumb {
        height: 180px;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__content {
        padding: 10px 15px;
        position: relative;
        height: 90px;
    }
    &__title {
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 0.03em;
        margin-top: 0;
        margin-bottom: 10px;
        @include line-clamp(2);
    }
    &__meta {
        position: absolute;
        width: calc(100% - 30px);
        top: 54px;
        overflow: hidden;
        height: 1.8em;
        line-height: 1.8;
        @include mq-down(md) {
            top: 58px;
        }
    }
    .post-date {
        font-size: 13px;
        font-weight: normal;
        margin-right: 20px;
    }
    .tag-item {
        font-size: 12px;
        margin-right: 5px;
    }
}
