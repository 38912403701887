@mixin space-loop($className, $styleName) {
    @for $i from 0 through 100 {
        @if $i % 5 == 0 {
            #{$className + $i} {
                #{$styleName}: #{$i}px !important;
            }
        }
    }
}
//padding
@include space-loop(".u-pt", "padding-top");
@include space-loop(".u-pb", "padding-bottom");
@include space-loop(".u-pl", "padding-left");
@include space-loop(".u-pr", "padding-right");
//margin
@include space-loop(".u-mt", "margin-top");
@include space-loop(".u-mb", "margin-bottom");
@include space-loop(".u-ml", "margin-left");
@include space-loop(".u-mr", "margin-right");

.u-text-center {
    text-align: center;
}

.u-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.u-mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.u-d-block {
    display: block !important;
}

.u-font-bold {
    font-weight: bold;
}

.u-pc {
    @include mq-down(md) {
        display: none;
    }
}

.u-sp {
    display: none;
    @include mq-down(md) {
        display: block;
    }
}
