// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
	width: map-get($columns, $numberColumns) - ( ( $columns__margin * ( $numberColumns - 1 ) ) / $numberColumns );
}


// レスポンシブ
// min-width(mobile-first)
$breakpoint-up: (
		'sm': 'screen and (min-width: 544px)',
		'md': 'screen and (min-width: 768px)',
		'lg': 'screen and (min-width: 992px)',
		'xl': 'screen and (min-width: 1230px)',
) !default;

// max-width(pc-first)
$breakpoint-down: (
		'sm': 'screen and (max-width: 543px)',
		'md': 'screen and (max-width: 767px)',
		'lg': 'screen and (max-width: 991px)',
		'xl': 'screen and (max-width: 1229px)',
) !default;
@mixin mq-up($breakpoint: md) {
	@media #{map-get($breakpoint-up, $breakpoint)} {
		@content;
	}
}
@mixin mq-down($breakpoint: md) {
	@media #{map-get($breakpoint-down, $breakpoint)} {
		@content;
	}
}

// 行数を指定するmixin
@mixin line-clamp($row) {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $row;
}
