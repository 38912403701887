.c-post-2 {
    background: #fff;
    transition: 0.2s;
    border: 1px solid #DDDDDD;
    &__thumb {
        overflow: hidden;
        & > div {
            padding-top: 55%;
            background-size: cover;
            background-position: center;
            transition: 0.5s;
        }
    }
    &__meta {
        font-size: 14px;
        letter-spacing: 0.15em;
        color: #5F5F5F;
        margin-bottom: 5px;
        @extend %font-en;
    }
    &__content {
        font-size: 16px;
        padding: 10px 15px 15px 15px;
        min-height: 80px;
        line-height: 1.5;
        @include mq-down(md) {
            padding: 10px;
        }
    }
    &__cat {
        font-size: 12px;
        color: #9B9B9B;
        margin-top: 10px;
        @include mq-down(md) {
            margin-top: 5px;
        }
    }
    &:hover {
        background: #E5E8EB;
        .c-post-2__thumb {
            > div {
                transform: scale(1.2);
            }
        }
    }
}
