body:lang(ja) {
    background: #B8D9D5;
    color: $cl-text;
    font-size: 18px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    @include mq-down(md) {
        font-size: 14px;
        padding-top: 80px;
    }
    a {
        color: $cl-text;
    }
}

// 1カラム時の共通レイアウト
.site-inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 720px;
    @include mq-up(md) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @include mq-up(lg) {
        padding-left: 40px;
        padding-right: 40px;
        max-width: 1180px;
    }
    @include mq-up(xl) {
        padding-left: 0;
        padding-right: 0;
    }
}

.site-content {
    @include mq-up(lg) {
        max-width: 1180px !important;
        padding-left: 40px;
        padding-right: 40px;
    }
    @include mq-up(xl) {
        padding-left: 0;
        padding-right: 0;
    }
}

section {
    margin-bottom: 80px;
    @include mq-down(md) {
        margin-bottom: 50px;
    }
}


.l-wrapper {
    width: 960px;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    display: block;
    @include mq-down(md) {
        border-radius: 0;
    }
}

.l-main {
    max-width: 95%;
    width: 700px;
    margin: 0 auto;
    padding-right: 0;
    @include mq-down(md) {
        width: 100%;
        max-width: 500px;
        padding: 0 20px 50px;
    }
}

// 親スタイル打ち消し
.archive {
    display: block;
    margin: 0;
}

.container {
    @include mq-down(md) {
        padding: 0 20px;
        max-width: 500px;
    }
}
