.c-section-title {
    font-size: 46px;
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    @include mq-down(md) {
        font-size: 34px;
    }
}
