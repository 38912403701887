// font-family: 'Cormorant', serif;
//@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,300;1,400;1,500;1,600&display=swap');

 //font-family: 'Noto Sans JP', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Sacramento&display=swap');

// font-family: 'Montserrat', sans-serif;
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');

// font-family: 'Cormorant Garamond', serif;
//@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,500&display=swap');

// font-family: 'Quattrocento Sans', sans-serif;
//@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans&display=swap');
//
// font-family: 'Sacramento', cursive;
//@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

// font-family: 'Barlow', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// font-family: 'Noto Serif JP', serif;
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@700&display=swap');

%font-en {
    font-family: 'Barlow', sans-serif;
}
.font-en {
    font-family: 'Barlow', sans-serif;
}
