.c-post-5 {
    background: #fff;
    transition: 0.2s;
    position: relative;
    &__thumb {
        overflow: hidden;
        & > div {
            padding-top: 100%;
            background-size: cover;
            background-position: center;
            transition: 0.5s;
        }
    }
    &__content {
        padding: 20px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: #fff;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    }
    &__title {
        font-size: 19px;
    }
    &__cat {
        font-size: 15px;
        letter-spacing: 0.2em;
    }
    &:hover {
        background: #E5E8EB;
        .c-post-5__thumb {
            > div {
                transform: scale(1.2);
            }
        }
    }
}
