.c-menu {
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 1001;
    cursor: pointer;
    .c-menu__line {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 3px;
        background: #26344a;
        transition: all 0.5s;
        transform: translate(-50%, -50%);
        &:before {
            display: block;
            content: "";
            position: absolute;
            top: -15px;
            left: 0;
            width: 100%;
            height: 100%;
            background: #26344a;
            transition: all 0.5s;
        }
        &:after {
            display: block;
            content: "";
            position: absolute;
            bottom: -15px;
            left: 0;
            width: 100%;
            height: 100%;
            background: #26344a;
            transition: all 0.5s;
        }
        &.c-menu__line--active {
            background: transparent;
            &:before {
                top: 0;
                transform: rotate(-45deg);
            }
            &:after {
                bottom: 0;
                transform: rotate(45deg);
            }
        }
    }
}
