h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	color: inherit;
	font-weight: normal;
	margin: 0.5em 0 1em;
}

h1 {

}

h2 {
	font-size: 28px;
	@include mq-down(md) {
		font-size: 22px;
	}
}

h3 {

}

h4 {

}
