.widget_businesspress_recent_posts {
    .widget-title {
        font-weight: normal;
    }
    li {
        border: 1px solid #E5E8EB;
        margin-bottom: 0;
        transition: 0.2s;
        height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:not(:first-of-type) {
            border-top: none;
        }
        &:hover {
            background: #E5E8EB;
        }
        a {
            height: 100%;
            .recent-posts-thumbnail {
                width: 100px;
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .recent-posts-text {
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .post-title {
                    font-size: 14px;
                    line-height: 1.71;
                    @include line-clamp(3)
                }
            }
        }
    }
}
