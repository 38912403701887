ul,
ol {
	padding: 0;
	margin: 0;
	list-style: none;
}

li > ul,
li > ol {
}

dt {
	font-weight: 700;
}

dd {
	margin: 0 1.5em 1.5em;
}
