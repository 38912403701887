//// hoverで色変更
//@mixin btn-hover($cl-text-before:$cl-text, $cl-text-after:$cl-text, $cl-border:#B1B1B1, $cl-bg-before:none, $cl-bg-after:#e5e8eb){
//    position: relative;
//    border: 1px solid $cl-border;
//    text-align: center;
//    transition: 0.2s;
//    width: 250px !important;
//    height: 57px;
//    letter-spacing: 0.09em;
//    font-size: 15px;
//    display: inline-block;
//    background: $cl-bg-before;
//    color: $cl-text-before;
//    @include mq-down(md) {
//        font-size: 14px;
//    }
//    a,span {
//        color:$cl-text-before;
//        position: relative;
//        z-index: 3;
//        background: initial;
//        border-radius: 0;
//        display: flex;
//        height: 100%;
//        justify-content: center;
//        align-items: center;
//        text-decoration: none !important;
//        padding: 0 10px 0 0;
//    }
//    &:after {
//        content: '';
//        position: absolute;
//        right: 1em;
//        top: 50%;
//        transform: translateY(-50%);
//        z-index: 3;
//        height: 100%;
//        width: 10px;
//        mask-image: url($image-dir + "arrow-right.svg");
//        mask-repeat: no-repeat;
//        mask-position: center;
//        mask-size: contain;
//        background-color: $cl-text-before;
//        transition: 0.2s;
//    }
//    &:hover {
//        color: $cl-text-after;
//        background: $cl-bg-after;
//        border-color: $cl-bg-after;
//        a {
//            color: $cl-text-after;
//        }
//        &:after {
//            background-color: $cl-text-after;
//        }
//    }
//}
//
//.c-btn {
//    @include btn-hover()
//}
//.c-btn--primary {
//    @include btn-hover(#fff, $cl-primary, none, $cl-primary, #e5e8eb);
//}

.c-btn {
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    min-width: 300px;
    display: inline-block;
    text-align: center;
    padding: 10px 30px 10px 20px;
    transition: 0.2s;
    position: relative;
    background: $cl-primary;
    width: auto;
    &:hover {
        opacity: 0.7;
        font-weight: normal;
        border: none;
    }
    &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        mask: url($image-dir + 'arrow-right.svg') no-repeat center;
        width: 10px;
        height: 12px;
        mask-size: contain;
    }
    &--red {
        background: #BF4533;
    }
    &--white {
        background: #F5F5F5;
        color: $cl-text;
        &:after {
            background-color: $cl-text;
        }
        &:hover {
            opacity: 1;
            color: $cl-text-hover;
            &:after {
                background-color: $cl-text-hover;
            }
        }
    }
}
