//// 使用サンプル
@mixin btn($cl-text-before:#454545, $cl-text-after:#454545, $cl-border:#B1B1B1, $cl-bg-before:none, $cl-bg-after:none) {
    position: relative;
    overflow: hidden;
    border: 1px solid $cl-border;
    text-align: center;
    outline: none;
    transition: ease .2s;
    width: 250px !important;
    height: 57px;
    letter-spacing: 0.09em;
    font-size: 15px;
    display: inline-block;
    background: $cl-bg-before;
    color: $cl-text-before;
    @include mq-down(md) {
        font-size: 14px;
    }
    a,span {
        color:$cl-text-before;
        position: relative;
        z-index: 3;
        background: initial;
        border-radius: 0;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
        padding: 0 10px 0 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        height: 100%;
        width: 10px;
        mask-image: url($image-dir + "arrow-right.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        background-color: $cl-text-before;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: $cl-bg-after;
        transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
        transform: scale(0, 1);
        transform-origin: right top;
    }
    &:hover {
        color: $cl-text-after;
        a {
            color: $cl-text-after;
            opacity: 1;
        }
        //&:before {
        //    transform-origin: left top;
        //    transform: scale(1, 1);
        //}
        //&:after {
        //    background-color: $cl-text-after;
        //}
    }
}

.c-btn-flow {
    @include btn();
    &--blue {
        @include btn(#fff, #fff, $cl-primary, $cl-primary, $cl-primary)
    }
    &--pink {
        @include btn(#fff, #F6A2B0, #FF9F9B, #F6A2B0, #F5F2F2)
    }
    &--gray {
        @include btn(#fff, #7C7C7C, #707070, #7C7C7C, #F5F2F2)
    }
    &--light-gray {
        @include btn(#454545, #454545, #CCCCCC, #F5F2F2, #fff)
    }
    &.btn-lg {
        width: 310px !important;
    }
    &--no-border {
        border: none;
    }
}
