.widget {
	margin: 0 0 50px;

	// Make sure select elements fit in widgets.
	select {
		max-width: 100%;
	}

	.widget-title {
		font-weight: normal;
		font-size: 18px;
		letter-spacing: 0.2em;
	}
}
