.c-underline {
    position: relative;
    padding-bottom: 3px;
    &:after {
        content: '';
        height: 100%;
        width: 0;
        border-bottom: 1px solid #B5B5B5;
        transition: 0.5s;
        position: absolute;
        left: 0;
        top: 0;
    }
    &:hover {
        &:after {
            width: 100%;
        }
    }
}

