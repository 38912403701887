@charset "UTF-8";
/*!
Theme Name:   LION MEDIA Child 2022
Theme URI:    http://fit-jp.com/theme/
Description:  FIT(フィット) が制作するメディア用WordPressTHEME「LION MEDIA」の子テーマ
Author:       Kota Naito　@FIT(フィット)
Author URI:   http://fit-jp.com/about/
Template:     lionmedia
Version:      1.2
License:      GNU GENERAL PUBLIC LICENSE
License URI:  http://www.gnu.org/licenses/gpl.html
*/

// sass --watch --style compact sass/style.scss:style.css

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Generic
	- Normalize
	- Box sizing
# Base
	- Typography
	- Elements
	- Links
	- Forms
## Layouts
# Components
	- Navigation
	- Posts and pages
	- Comments
	- Widgets
	- Media
	- Captions
	- Galleries
# plugins
	- Jetpack infinite scroll
# Utilities
	- Accessibility
	- Alignments

--------------------------------------------------------------*/

// Import variables and mixins.
@import "abstracts/variables/colors";
@import "abstracts/variables/typography";
@import "abstracts/variables/columns";
@import "abstracts/variables/setting";
@import "abstracts/mixins/mixins";


/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/
@import "generic/_box-sizing.scss";
@import "generic/_common-style.scss";
@import "generic/_fonts.scss";
@import "generic/_normalize.scss";
@import "generic/_reset-parent-theme.scss";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "base/lib/_slick.scss";

/* Typography
--------------------------------------------- */
@import "base/typography/_block-editor.scss";
@import "base/typography/_copy.scss";
@import "base/typography/_headings.scss";
@import "base/typography/_typography.scss";

/* Elements
--------------------------------------------- */
@import "base/elements/body";
@import "base/elements/hr";
@import "base/elements/lists";
@import "base/elements/media";
@import "base/elements/tables";

/* Links
--------------------------------------------- */
@import "base/elements/links";

/* Forms
--------------------------------------------- */
@import "base/elements/buttons";
@import "base/elements/fields";


/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
@import "layouts/_base.scss";
@import "layouts/_content.scss";
@import "layouts/_footer.scss";
@import "layouts/_header.scss";

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import "components/content/_posts-and-pages.scss";
@import "components/navigation/_breadcrumbs.scss";
@import "components/navigation/_navigation.scss";
@import "components/navigation/_pagination.scss";
@import "components/widgets/_businesspress_recent_posts.scss";
@import "components/widgets/_categories.scss";
@import "components/widgets/_widgets.scss";
@import "components/button/_btn.scss";
@import "components/post/_post-sm.scss";
@import "components/post/_post.scss";
@import "components/_block.scss";
@import "components/_btn-flow.scss";
@import "components/_btn.scss";
@import "components/_columns-2.scss";
@import "components/_columns-3.scss";
@import "components/_columns-4.scss";
@import "components/_common.scss";
@import "components/_font-en.scss";
@import "components/_full-width.scss";
@import "components/_img-lg.scss";
@import "components/_menu.scss";
@import "components/_overlay.scss";
@import "components/_post-1.scss";
@import "components/_post-2.scss";
@import "components/_post-5.scss";
@import "components/_post-artist-2.scss";
@import "components/_section-title.scss";
@import "components/_text-hover.scss";
@import "components/_underline-fade.scss";
@import "components/_underline-usual.scss";
@import "components/_underline.scss";

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/
@import "plugins/_contact-form-7.scss";
@import "plugins/_mw-wp-form.scss";

/* Jetpack infinite scroll
--------------------------------------------- */
@import "plugins/jetpack/infinite-scroll";

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/
@import "utilities/_utility.scss";

/* Accessibility
--------------------------------------------- */

/* Alignments
--------------------------------------------- */

/*--------------------------------------------------------------
# Projects
--------------------------------------------------------------*/
@import "projects/_common-style.scss";
@import "projects/_concert-slider.scss";
@import "projects/_page-header.scss";
@import "projects/_post-news.scss";
@import "projects/_sidebar.scss";


/*--------------------------------------------------------------
# Pages
--------------------------------------------------------------*/
@import "pages/_archive.scss";
@import "pages/_front-page.scss";
@import "pages/_lp.scss";
@import "pages/_page.scss";
@import "pages/_single.scss";


.socialList {
    a {
        color: #fff !important;
    }
}

.single-bottom-banner {
    margin-top: 40px;
    a {
        display: block;
        transition: 0.2s;
        &:hover {
            opacity: 0.8;
        }
        &:first-of-type {
            margin-bottom: 30px;
            @include mq-down(md) {
                margin-bottom: 20px;
            }
        }
        img {
            border-radius: 6px;
        }
    }
}

.posts-guid {
    margin-top: 40px;
    border: 2px solid #000;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    &:after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        background: #000;
        @include mq-down(md) {
            left: 0;
            top: 50%;
            height: 1px;
            width: 100%;
            transform: translateY(-50%);
        }
    }
    a {
        width: 50%;
        padding: 20px;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.5;
        display: flex;
        position: relative;
        height: 110px;
        @include line-clamp(3);
        transition: 0.2s;
        &:hover {
            background: #E6EDF4;
        }
        @include mq-down(md) {
            display: flex;
            align-items: center;
            width: 100%;
        }
    }
    a.post-prev {
        float: left;
        padding-left: 40px;
        @include mq-down(md) {
            float: none;
            padding-left: 50px;
        }
        &:after {
            content: '';
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            background: url($image-dir + 'arrow-black-left.svg') no-repeat center;
            background-size: contain;
            width: 10px;
            height: 20px;
            @include mq-down(md) {
                left: 20px;
            }
        }
    }
    a.post-next {
        float: right;
        padding-right: 40px;
        @include mq-down(md) {
            float: none;
            padding-right: 50px;
        }
        &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            background: url($image-dir + 'arrow-black-right.svg') no-repeat center;
            background-size: contain;
            width: 10px;
            height: 20px;
            @include mq-down(md) {
                right: 20px;
            }
        }
    }
}

.related {
    border-top: none;
}
