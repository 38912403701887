.entry-header {
    h1 {
        @extend .p-heading-1;
        margin-bottom: 10px;
        @include mq-down(md) {
            margin: 30px 0 10px;
        }
    }
}

.banner-lg {
    position: relative;
    margin-top: 100px;
    display: block;
    .banner-icon {
        position: absolute;
        left: -20px;
        top: -25px;
        width: 150px;
        @include mq-down(md) {
            top: -35px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .banner-image {
        border-radius: 10px;
    }
}

.bottom-tags {
    margin-top: 40px;
}

.bottom-tags, .entry-header {
    .post-date {
        font-size: 13px;
        letter-spacing: 0.02em;
        color: #A7A4A4;
        margin-right: 10px;
    }
    .cat-item {
        font-size: 11.5px;
        border: 1px solid #B7B7B7;
        border-radius: 3px;
        color: #A7A4A4;
        padding: 1px 5px;
        margin-right: 10px;
    }
    .tag-item {
        font-size: 11.5px;
        color: #A7A4A4;
        position: relative;
        padding-left: 18px;
        margin-right: 10px;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url($image-dir + 'icon-tag.png') no-repeat left;
            background-size: contain;
            width: 14px;
            height: 14px;
        }
    }
}


.socialList {
    margin-top: 40px;
}

.profile {
    border-top: none;
    padding-top: 0;
    &__title {
        font-size: 20px;
        letter-spacing: 0.13em;
        margin-bottom: 20px;
        font-weight: bold;
        @include mq-down(md) {
            font-size: 18px;
        }
    }
    &__imgArea {
        width: 90px;
        height: 90px;
        @include mq-down(md) {
            width: 60px;
            height: 60px;
        }
    }
    &__contents {
        width: calc(100% - 110px);
        @include mq-down(md) {
            width: calc(100% - 80px);
        }
    }
    &__name {
        display: inline-block;
        font-weight: bold;
        margin-top: 0;
    }
    &__group {
        display: inline-block;
        font-weight: bold;
        font-size: 13px;
        color: $cl-text;
        letter-spacing: 0.22em;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    &__discription {
        font-size: 14px;
        @include mq-down(md) {
            font-size: 14px !important;
        }
    }
    &__list {
        display: flex;
        margin-top: 10px;
    }
    &__item {
        margin-right: 20px;
    }
    &__link {
        background: none;
        font-size: 20px;
        &:hover {
            background: none !important;
        }
    }
    .icon-twitter {
        color: #1D9AEF;
    }
    .icon-facebook {
        color: #1B74E4;
    }
}

.content {
    @include mq-down(md) {
        margin-top: 50px;
    }
}
