.p-concert-slider {
    width: 100%;
    li {
        padding: 1px;
        a {
            height: 100%;
            display: block;
            overflow: hidden;
            img {
                transition: 0.5s;
                height: 100%;
                width: auto;
                object-fit: cover;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    .slick-list {
        @include mq-down(md) {
            height: 150px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
