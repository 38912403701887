.archiveTitle {
    position: relative;
    margin-bottom: 80px;
    h1 {
        @extend .p-heading-1;
        @include mq-down(md) {
            text-align: center;
            padding-bottom: 120px;
        }
    }
    img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 225px;
        height: 170px;
        object-fit: contain;
        @include mq-down(md) {
            left: 50%;
            transform: translateX(-50%);
            top: 50px;
            width: 200px;
            height: 150px;
            object-fit: contain;
        }
    }
}
