.l-footer {
    background: none;
    padding-top: 50px;
    .footer-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        @include mq-down(md) {
            display: block;
        }
        .footer-left {
            @include mq-down(md) {
                margin-bottom: 50px;
            }
            .footer-title {
                &__sub {
                    font-size: 14px;
                    letter-spacing: 0.13em;
                    font-weight: 500;
                    margin-bottom: 10px;
                    display: block;
                }
                &__main {
                    display: block;
                    width: 220px;
                    margin-bottom: 20px;
                }
            }
            .profile {
                display: flex;
                a {
                    color: $cl-text;
                    transition: 0.2s;
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
        .footer-right {
            display: flex;
            font-size: 14px;
            font-weight: 500;
            @include mq-down(md) {
                display: block;
            }
            ul {
                margin: 0 30px;
                @include mq-down(md) {
                    margin: 0;
                }
                li {
                    margin-bottom: 10px;
                    a {
                        transition: 0.2s;
                        @include mq-down(md) {
                            display: block;
                            padding: 5px 0;
                        }
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }

    .copySns {
        letter-spacing: 0.1em;
        &__copy {
            width: 100%;
            text-align: center;
            color: #4D4E58;
            font-size: 11px;
            @include mq-down(md) {
                text-align: left;
            }
        }
        &__copyLink {
            font-weight: normal;
            text-decoration: none;
            font-size: 11px;
        }
    }


    .pagetop {
        position: fixed;
        right: 20px;
        bottom: 20px;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        border: 1px solid #fff;
        overflow: hidden;
        background: rgba(255,255,255,0.7);
        transition: 0.2s;
        z-index: 10;
        @include mq-down(md) {
            width: 70px;
            height: 70px;
            border-width: 2px;
            right: 10px;
            bottom: 10px;
        }
        &:hover {
            background: $cl-primary;
        }
        &:before {
            content: none;
        }
        &__link {
            color: #77B0AB;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            &:before {
                content: none;
            }
            img {
                width: 60%;
                transition: 0.2s;
            }
            &:hover {
                background: none;
            }
        }
    }

    .profile {
        margin: 0;
        &__item {
            margin: 0 20px 0 0;
        }
    }
}
