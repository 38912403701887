.mw_wp_form {
    width: 700px;
    @include mq-down(md) {
        width: 100%;
    }
    margin: 50px auto 0;
    @include mq-down(md) {
        margin-top: 0;
    }
    input[type="text"], input[type="email"] {
        width: 100%;
    }
    select, input, textarea {
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 3px;
        @include mq-down(md) {
            padding: 10px;
            font-size: 14px;
        }
    }
    input[type="number"] {
        width: 50px;
        padding: 10px;
    }
    button[type="submit"] {
        margin: 50px 10px;
        display: inline-block;
        @extend .c-btn;
        @include mq-down(md) {
            margin: 10px 0;
        }
    }
    .link-privacy {
        text-align: center;
        font-size: 14px;
        text-decoration: underline;
    }
    .custom-select {
        position: relative;
        select {
            appearance: none;
        }
        &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            pointer-events: none;
            background: url($image-dir + 'arrow-bottom.svg') no-repeat center;
        }
    }

    &.mw_wp_form_confirm {
        label {
            background: #E5E8EB;
            padding: 5px 10px;
            line-height: 1.5 !important;
        }
        .custom-select {
            &:after {
                display: none;
            }
        }
    }
}
