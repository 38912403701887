.c-columns-2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > * {
        width: 48%;
        margin-bottom: 25px;
        @include mq-down(md) {
            width: 100%;
        }
    }
}
