.c-post-1 {
    background: #fff;
    border: 1px solid #E5E8EB;
    @include mq-down(md) {
        display: flex;
    }
    &__thumb {
        position: relative;
        padding-top: 55%;
        background-size: cover;
        background-position: center;
        @include mq-down(md) {
            width: 40%;
            padding-top: 40%;
        }
    }
    &__content {
        padding: 15px;
        min-height: 80px;
        @include mq-down(md) {
            width: 60%;
        }
        .post-cat {
            display: inline-block;
            color: $cl-primary;
            background: $cl-pink;
            border: 1px solid $cl-primary;
            font-size: 14px;
            padding: 0 20px;
            @include mq-down(md) {
                font-size: 12px;
            }
        }
        .post-title {
            margin-top: 10px;
            font-size: 17px;
            @include mq-down(md) {
                font-size: 14px;
            }
        }
    }
    &:hover {
        background: #E5E8EB;
    }
}
