.page-inner {
    max-width: 940px;
    margin: 0 auto;
}

.tag-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    li {
        border: 1px solid #CCC;
        display: inline-block;
        padding: 3px 10px;
        margin: 5px 10px;
        @include mq-down(md) {
            font-size: 14px;
            padding: 3px 5px;
            margin: 5px;
        }
    }
}

.page-id-7747 {
    form {
        display: none;
    }
}
