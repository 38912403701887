.c-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: #F4F8EB;
    opacity: 0;
    visibility: hidden;
    transition: .2s linear;
    padding: 20px;
    @include mq-down(md) {
        height: calc(100% - 60px);
        padding: 10px;
    }
    &.is-open {
        opacity: 1;
        visibility: visible;
    }
}
