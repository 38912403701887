// 上書き用
.content.custom-post-style, .mce-content-body {
    line-height: 2.1;
    font-size: 18px;
    letter-spacing: 0.02em;
    @include mq-down(md) {
        font-size: 16px;
        line-height: 2;
    }
    img {
        width: auto;
    }
    .flex-between {
        display: flex;
        justify-content: space-between;
        @include mq-down(md) {
            flex-direction: column;
        }
        .btn {
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    a:not([class]) {
        color: #0086D1 !important;
        transition: 0.2s;
        &:hover {
            text-decoration: underline;
            text-decoration-color: #0086D1;
            text-underline-offset: 5px;
            font-weight: normal;
            border: none;
        }
    }
    a.arrow {
        color: #0086D1 !important;
        transition: 0.2s;
        padding-left: 25px;
        position: relative;
        display: inline-block;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-image: url($image-dir + 'icon-a.svg');
            background-repeat: no-repeat;
            background-position: center;
        }
        &:hover {
            text-decoration: underline;
            text-decoration-color: #0086D1;
            text-underline-offset: 5px;
            font-weight: normal;
            border: none;
        }
    }
    .bgBox, .borderBox, .border2Box, .exclamationBox, .worryBox {
        & > * {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .bgBox {
        background: #F4F2F0;
        border-radius: 6px;
        padding: 25px;
    }
    .borderBox {
        border-radius: 6px;
        border-color: #DBEFEC;
        border-width: 2px;
        padding: 25px;
        @include mq-down(md) {
            padding: 15px;
            img {
                width: 100%;
            }
        }
        ul {
            li {
                &:before {
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 14px;
                    background: url($image-dir + 'icon-check.png') no-repeat;
                    width: 20px;
                    height: 12px;
                    background-size: contain;
                }
            }
        }
        &--gray {
            border-color: #E6E6E6;
        }
    }
    .exclamationBox {
        background: rgba(255,107,140,0.15);
        position: relative;
        padding: 30px;
        z-index: 1;
        border-radius: 6px;
        &:before {
            content: '';
            background: url($image-dir + 'icon-exclamation.png');
            background-size: contain;
            position: absolute;
            left: 20px;
            top: -22px;
            width: 45px;
            height: 45px;
            z-index: 10;
        }
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;
            background: url($image-dir + 'bg.png') repeat;
            background-size: contain;
        }
        * {
            position: relative;
            z-index: 10;
        }
    }
    .worryBox {
        padding: 30px;
        border: 2px solid #A4CCE5;
        border-radius: 3px;
        position: relative;
        margin-top: 50px;
        color: #2EA9DF;
        &:before {
            content: '';
            position: absolute;
            left: 30px;
            top: -25px;
            background: url($image-dir + 'onayami.png') no-repeat center;
            width: 150px;
            height: 50px;
            background-size: contain;
        }
    }
    .markerYellow {
        background: linear-gradient(transparent 65%, #E8EAB2 65%);
    }
    .markerBlue {
        background: linear-gradient(transparent 65%, #E6EDF4 65%);
    }

    h2 {
        @extend .p-heading-2;
    }

    h3 {
        @extend .p-heading-3;
    }

    ul {
        @extend .p-ul;
    }

    ol {
        @extend .p-ol;
    }

    blockquote:not([class]) {
        margin-left: 0;
        margin-right: 0;
        background: none;
        padding: 0;
        &:before {
            content: none;
        }
        .blockquote-before, .blockquote-after {
            position: relative;
            img {
                width: 40px;
                height: auto;
            }
        }
        .blockquote-before {
            &:after {
                content: '';
                position: absolute;
                left: 50px;
                top: 50%;
                transform: translateY(-50%);
                width: calc(100% - 50px);
                height: 1px;
                background: #CECECE;
            }
        }
        .blockquote-after {
            text-align: right;
            &:before {
                content: '';
                position: absolute;
                right: 50px;
                top: 50%;
                transform: translateY(-50%);
                width: calc(100% - 50px);
                height: 1px;
                background: #CECECE;
            }
        }
    }
    .lyricsBox {
        margin-left: 0;
        margin-right: 0;
        background: rgba(247, 217, 76, 0.15);
        padding: 40px 30px;
        position: relative;
        font-size: 16px;
        letter-spacing: 0.2em;
        line-height: 2.5;
        z-index: 1;
        border-radius: 6px;
        overflow: hidden;
        @include mq-down(md) {
            padding: 30px 25px;
        }
        &:before {
            content: none;
        }
        h3 {
            letter-spacing: 0.4em;
            &:not(:first-of-type) {
                margin-top: 2em;
            }
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: url($image-dir + 'bg.png') repeat;
        }
        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .wp-caption-text {
        text-align: right;
    }

    .balloon {
        display: flex;
        position: relative;
        justify-content: space-between;
        @include mq-down(md) {
            display: block;
            flex-wrap: wrap;
        }
        .name {
            position: absolute;
            left: 0;
            top: 80px;
            font-size: 14px;
            margin-bottom: 0;
            text-align: center;
            display: block;
            width: 75px;
            @include mq-down(md) {
                top: 10px;
                left: 85px;
                text-align: left;
                width: auto;
            }
        }
        &__image {
            width: 75px;
            img {
                width: 75px;
                height: 75px;
                border-radius: 50%;
            }
            @include mq-down(md) {
                padding-left: 25px;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
        &__textboxWrapper {
            width: calc(100% - 120px);
            @include mq-down(md) {
                width: 100%;
            }
        }
        &__textbox {
            position: relative;
            display: inline-block;
            max-width: 100%;
            background: #FFF0D0;
            padding: 25px 30px;
            border-radius: 10px;
            &::before {
                content: "";
                position: absolute;
                top: 50px;
                left: -30px;
                margin-top: -15px;
                border: 15px solid transparent;
                border-right: 15px solid #FFF0D0;
                @include mq-down(md) {
                    top: -30px;
                    left: 50px;
                    margin-top: 0;
                    margin-left: -15px;
                    border: 15px solid transparent;
                    border-bottom: 15px solid #FFF0D0;
                }
            }
        }
    }
    .right {
        .name {
            left: auto;
            right: 0;
            @include mq-down(md) {
                left: auto;
                right: 85px;
            }
        }
        .balloon__image {
            order: 2;
            @include mq-down(md) {
                padding-left: 0;
                padding-right: 25px;
                text-align: right;
            }
        }
        .balloon__textboxWrapper {
            order: 1;
        }
        .balloon__textbox {
            background: #E6EDF4;
            &:before {
                content: "";
                position: absolute;
                top: 50px;
                right: auto;
                left: 100%;
                margin-top: -15px;
                border: 15px solid transparent;
                border-left: 15px solid #E6EDF4;
                @include mq-down(md) {
                    top: -30px;
                    left: auto;
                    right: 35px;
                    margin-top: 0;
                    margin-left: -15px;
                    border: 15px solid transparent;
                    border-bottom: 15px solid #E6EDF4;
                }
            }
        }
    }

    // @todo 重複してる
    .btn {
        font-size: 18px;
        color: #fff;
        border-radius: 5px;
        min-width: 300px;
        display: inline-block;
        text-align: center;
        padding: 15px 30px 15px 20px;
        transition: 0.2s;
        position: relative;
        background: $cl-primary;
        width: auto;
        margin-right: 20px;
        @include mq-down(md) {
            width: 100%;
            margin-right: 0;
        }
        &:hover {
            opacity: 0.7;
            font-weight: normal;
            border: none;
        }
        &:after {
            content: '';
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            background: url($image-dir + 'arrow-right.svg') no-repeat center;
            width: 10px;
            height: 12px;
            background-size: contain;
        }
        &--red {
            background: #BF4533;
        }
        &--orange {
            background: #F79400;
        }
        &--blue {
            background: #009BE8;
        }
    }

    .post-link {
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        border: 2px solid #E5E8EB;
        transition: 0.2s;
        height: 120px;
        width: 100%;
        margin: 20px 0;
        overflow: hidden;
        @include mq-down(md) {
            height: 130px;
        }
        &__content {
            width: 65%;
            letter-spacing: 0.02em;
            padding: 20px 25px;
            @include mq-down(md) {
                padding: 15px 20px;
            }
            .post-title {
                font-size: 17px;
                font-weight: bold;
                line-height: 1.58;
                @include line-clamp(2);
                @include mq-down(md) {
                    font-size: 15.5px;
                    @include line-clamp(3);
                }
            }
            .post-url {
                color: #696969;
                font-size: 12px;
                font-weight: normal !important;
                @include mq-down(md) {
                    @include line-clamp(1);
                }
            }
        }
        &__img {
            width: 35%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:hover {
            opacity: 0.6;
        }
    }
}

.mce-content-body {
    blockquote:not([class]) {
        border: 1px solid #cecece;
        border-right: none;
        border-left: none;
        padding: 10px 0;
    }
}
