.sidebar-area {
    max-width: 420px;
}
.p-custom-sidebar {
    @include mq-down(sm) {
        width: calc(100vw);
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: calc(-50vw);
        margin-right: calc(-50vw);
    }
    .sidebar-item {
        margin-bottom: 50px;
    }

    .sidebar-item-workshop, .sidebar-item-class {
        @include mq-down(md) {
            margin-bottom: 0;
        }
    }

    .items {
        letter-spacing: 0.2em;
        &__title {
            border-top: 1px solid #DEDEDE;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 90px;
            height: 80px;
            @include mq-down(md) {
                height: 70px;
                padding-left: 70px;
            }
            .icon {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 60px;
                @include mq-down(md) {
                    width: 45px;
                }
            }
            span {
                display: none;
                @include mq-down(md) {
                    display: flex;
                    position: absolute;
                    right: 20px;
                    width: 30px;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 10px;
                        height: auto;
                        transition: 0.2s;
                    }
                    &.is-active {
                        img {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
        .items-list {
            @include mq-down(md) {
                display: none;
                margin-bottom: 20px;
                padding: 0 20px;
            }
            li {
                margin-bottom: 2px;
                a {
                    display: block;
                    padding: 15px 30px;
                }
                &:nth-of-type(odd) {
                    background: rgba(245,242,242,1);
                }
                &:nth-of-type(even) {
                    background: rgba(245,242,242,0.5);
                }
                &:hover {
                    background: #A7A5A5;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .link {
        .link-list {
            li {
                border-top: 1px solid #DEDEDE;
                &:last-of-type {
                    border-bottom: 1px solid #DEDEDE;
                }
                &:hover {
                    background: #F5F2F2;
                }
                a {
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 90px;
                    height: 80px;
                    @include mq-down(md) {
                        height: 70px;
                        padding-left: 70px;
                    }
                    img {
                        position: absolute;
                        left: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 60px;
                        @include mq-down(md) {
                            width: 45px;
                        }
                    }
                }
            }
        }
    }

    .schedule {
        padding: 0 20px;
        img {
            transition: 0.2s;
        }
        &:hover {
            img {
                opacity: 0.5;
            }
        }
    }
}
