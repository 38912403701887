.wpcf7 {
    font-size: 18px;
    textarea {
        padding: 5px 10px;
    }
    select {
        width: 300px;
        padding: 10px;
        font-size: 18px;
    }
    .wpcf7-text {
        width: 300px;
        padding: 10px 10px;
    }
    button[type="submit"] {
        @extend .c-btn-flow;
    }
    input[type="checkbox"] {
        transform: scale(1.2);
    }
    input[type="number"] {
        padding: 10px;
    }
    .wpcf7-checkbox {
        .wpcf7-list-item {
            display: block;
            margin-left: 0;
            margin-bottom: 5px;
        }
    }
    .wpcf7-radio {
        .wpcf7-list-item {
            margin-right: 50px;
            margin-bottom: 5px;
        }
    }
}
