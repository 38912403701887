.page-template-lp {
    padding-top: 0;
    @include mq-down(md) {
        padding-top: 0 !important;
    }
    .l-header {
        background: none;
        display: flex;
        align-items: center;
        .siteTitle {
            opacity: 0;
            pointer-events: none;
            transition: 0.5s;
        }
        .menuNavi {
            display: none;
        }
        .container {
            justify-content: space-between;
        }
    }
    .is-open + .l-header {
        .siteTitle {
            opacity: 1;
            pointer-events: auto;
        }
        .menuNavi {
            display: flex;
        }
    }
    .l-extra {
        display: none;
    }
    .l-wrapper {
        border-radius: 0 0 20px 20px;
        position: relative;
        top: -5px;
        @include mq-down(md) {
            border-radius: 0;
        }
    }
    .main-image {

    }
    .l-footer {
        .footer-content {
            justify-content: center;
            margin-bottom: 0;
            .footer-left {
                @include mq-down(md) {
                    margin-bottom: 0;
                    .footer-title {
                        text-align: center;
                        &__main {
                            margin: 0 auto;
                        }
                    }
                }
                .profile {
                    display: none;
                }
            }
            .footer-right {
                display: none;
            }
        }
        .copySns {
            &__copy {
                text-align: center;
            }
        }
    }
}
