.c-block {
    border: 1px solid #fff;
    position: relative;
    z-index: 1;
    padding: 20px 30px;
    &:before {
        content: '';
        position: absolute;
        background: #fff;
        width: 100%;
        height: 100%;
        left: 10px;
        top: 10px;
        opacity: .5;
        z-index: -1;
    }
}
