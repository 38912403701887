.c-post-artist-2 {
    .post-header {
        background-image: url($image-dir + 'artist-bg-1.png');
        background-size: 100% 100%;
        display: flex;
        padding: 50px 50px 0 50px;
        @include mq-down(md) {
            display: block;
            padding: 30px 30px 0 30px;
            background-image: url($image-dir + 'artist-bg-1-sp.png');
        }
        .artist-name {
            font-size: 20px;
            color: #000;
            letter-spacing: 0.06em;
            margin-right: 30px;
            font-weight: 700;
            @include mq-down(md) {
                margin-right: 0;
                line-height: 1;
            }
        }
        .artist-name-en {
            color: #5F5F5F;
            @extend %font-en;
            letter-spacing: 0.15em;
            position: relative;
            bottom: -8px;
            @include mq-down(md) {
                margin-bottom: 25px;
                line-height: 1;
            }
        }
        .artist-cat {
            color: #000;
            margin-left: auto;
            margin-right: 0;
            background: #fff;
            font-weight: 700;
            padding: 5px 15px;
            letter-spacing: 0.06em;
            @include mq-down(md) {
                margin: 0;
                display: inline-block;
                padding: 0 10px;
            }
        }
    }
    .post-content {
        padding: 30px 50px;
        color: #000;
        background-image: url($image-dir + 'artist-bg-2.png');
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        font-size: 15.5px;
        @include mq-down(md) {
            flex-wrap: wrap;
            flex-direction: column;
            padding: 30px 30px 0;
            background-image: url($image-dir + 'artist-bg-2-sp.png');
        }
        &__left {
            width: 65%;
            line-height: 2;
            @include line-clamp(15);
            @include mq-down(md) {
                width: 100%;
                order: 2;
                @include line-clamp(5);
            }
        }
        &__right {
            padding-top: 8px;
            width: 33%;
            @include mq-down(md) {
                order: 1;
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    .post-footer {
        padding: 30px;
        text-align: center;
        background-image: url($image-dir + 'artist-bg-3.png');
        background-size: 100% 100%;
        @include mq-down(md) {
            background-image: url($image-dir + 'artist-bg-3-sp.png');
        }
    }
}
