body {
    padding-top: 95px;
}

.l-header {
    background: rgba(244,248,235,0.7);
    height: 95px;
    z-index: 10001;
    position: fixed;
    top: 0;
    .container {
        @include mq-down(md) {
            max-width: initial;
        }
    }
    @include mq-down(md) {
        height: 80px;
    }
    .container {
        width: 95%;
        display: flex;
        justify-content: space-between;
        &:after {
            content: none;
        }
        @include mq-down(md) {
            width: 100%;
        }
    }
    .siteTitle {
        height: 65px;
        margin-top: 28px;
        width: auto;
        @include mq-down(md) {
            margin-top: 10px;
        }
        &__link {
            @include mq-down(md) {
                float: none;
                height: auto;
            }
            &:hover {
                opacity: 1;
            }
        }
        &__main {
            @include mq-down(md) {
                float: none;
                display: block;
                svg {
                    width: 180px;
                }
            }
        }
        &__sub {
            color: $cl-text;
            font-size: 14px;
            margin-left: 30px;
            letter-spacing: 0.13em;
            font-weight: 500;
            margin-top: 5px;
            @include mq-down(md) {
                float: none;
                display: block;
                margin: 0;
                font-size: 12px;
                font-weight: normal;
            }
        }

    }

    .menuNavi {
        margin-left: auto;
        margin-right: 20px;
        width: auto;
        max-width: initial;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 20px;
        }
        &__item {
            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
        @include mq-down(xl) {
            display: none !important;
        }
        .contact-btn {
            color: $cl-primary;
            border: 2px solid $cl-primary;
            border-radius: 6px;
            padding: 10px 30px 10px 20px;
            letter-spacing: 0.13em;
            background: #F4F8EB;
            position: relative;
            transition: 0.2s;
            font-size: 16px;
            font-weight: bold;
            &:hover {
                opacity: 0.7;
            }
            &:after {
                content: '';
                position: absolute;
                right: 5px;
                top: 51%;
                transform: translateY(-50%);
                width: 15px;
                height: 15px;
                background: url($image-dir + 'arrow-primary.png') no-repeat center;
                background-size: contain;
            }
        }
        .search-btn {
            cursor: pointer;
            width: 32px;
            height: 32px;
            .search-box {
                display: none;
                position: absolute;
                top: 95px;
                right: 10px;
                transition: 0.2s;
                width: 370px;
                border-radius: 6px;
                input {
                    appearance: none;
                    border: 2px solid $cl-primary;
                    display: inline-block;
                    width: 370px;
                    height: 47px;
                    padding: 10px 50px 10px 10px;
                    border-radius: 6px;
                    font-size: 16px;
                    &:focus {
                        outline: none;
                    }
                    &::placeholder {
                        color: #E5E8EB;
                    }
                }
                label {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    height: 47px;
                    line-height: 47px;
                    color: white;
                    background: $cl-primary;
                    border-radius: 0 6px 6px 0;
                    padding: 0 20px;
                    font-size: 16px;
                }
                &.active {
                    display: block;
                }
            }
        }
    }

    .menu-btn {
        display: flex;
        align-items: center;
    }
}

.p-header-btn {
    border: 2px solid #B58E59;
    background: #BF4533;
    padding: 10px 30px 10px 20px;
    border-radius: 5px;
    color: #fff !important;
    letter-spacing: 0.13em;
    font-weight: bold;
    position: relative;
    font-size: 16px;
    transition: 0.2s;
    &:after {
        content: '';
        position: absolute;
        right: 5px;
        top: 51%;
        transform: translateY(-50%);
        background: url($image-dir + 'arrow-right.svg') no-repeat center;
        width: 20px;
        height: 20px;
    }
    &:hover {
        opacity: 0.6;
    }
}


.l-extra {
    background: #4D4E58;
    width: 100%;
    @include mq-down(md) {
        display: none;
    }
    .container {
        width: $site-width;
    }
    .globalNavi {
        &__list {
            margin: 0 auto;
            .menu-item {
                border-left: none;
                font-size: 14px;
                letter-spacing: 0.13em;
                font-weight: 500;
                &:hover {
                    font-weight: normal;
                }
                a {
                    color: #fff;
                }
            }
        }
    }
}

.breadcrumb {
    background: none;
    padding: 20px 0;
    @include mq-down(md) {
        padding: 15px 0;
    }
    .container {
        width: $content-width;
    }
    &__item {
        font-weight: bold;
        letter-spacing: 0.13em;
        color: #707070;
        &:after {
            border-color: #707070;
        }
        a {
            color: #707070 !important;
        }
        .icon-home {
            color: #707070;
            font-weight: bold;
            &:before {
                content: none;
            }
        }
    }
}

#overlay {
    padding-top: 95px;
    padding-bottom: 20%;
    @include mq-down(md) {
        height: 100%;
        overflow-y: scroll;
    }
    .inner {
        display: flex;
        justify-content: space-between;
        max-width: 700px;
        margin: 0 auto;
        align-items: center;
        height: 100%;
        @include mq-down(md) {
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 10px;
            height: auto;
        }
        ul {
            width: 45%;
            @include mq-down(md) {
                width: 100%;
            }
            li {
                &:last-of-type {
                    margin-bottom: 0;
                }
                a {
                    font-weight: 500;
                    padding: 10px 0;
                    display: block;
                    transition: 0.2s;
                    letter-spacing: 0.02em;
                    &:hover {
                        opacity: 0.6;
                    }
                    @include mq-down(md) {
                        position: relative;
                        font-size: 15.5px;
                        padding-left: 20px;
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 12px;
                            height: 12px;
                            background: url($image-dir + 'arrow-black-right.svg') no-repeat center;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }
    .overlay-bottom {
        position: fixed;
        bottom: 5%;
        width: 95%;
        @include mq-down(md) {
            position: static;
            width: 100%;
        }
    }
    .profile {
        display: flex;
        justify-content: center;
        @include mq-down(md) {
            justify-content: flex-start;
            margin-left: 10px;
            margin-top: 50px;
            margin-bottom: 50px;
        }
        li {
            &:last-of-type {
                margin-right: 0;
            }
        }
        &__item {
            margin: 0;
            &:first-of-type {
                margin-right: 15px;
            }
        }
    }

    .contact-btn {
        @include mq-up(md) {
            display: none;
        }
        margin-top: 20px;
        width: 100%;
        color: $cl-primary;
        border: 2px solid $cl-primary;
        border-radius: 6px;
        padding: 10px 30px 10px 20px;
        letter-spacing: 0.13em;
        background: #F4F8EB;
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 16px;
        &:hover {
            opacity: 0.7;
        }
        &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            height: 15px;
            background: url($image-dir + 'arrow-primary.png') no-repeat center;
            background-size: contain;
        }
    }
    .search-btn {
        @include mq-up(md) {
            display: none;
        }
        .search-box {
            position: relative;
            width: 100%;
            border-radius: 6px;
            input {
                appearance: none;
                border: 2px solid $cl-primary;
                display: inline-block;
                width: 100%;
                height: 50px;
                padding: 10px 80px 10px 10px;
                &:focus {
                    outline: none;
                }
                &::placeholder {
                    color: #E5E8EB;
                }
            }
            label {
                position: absolute;
                top: 0;
                right: 2px;
                bottom: 0;
                height: 50px;
                line-height: 50px;
                color: white;
                background: $cl-primary;
                border-radius: 3px 0 0 3px;
                padding: 0 20px;
                font-size: 16px;
            }
            &.active {
                display: block;
            }
        }
    }
    .p-header-btn {
        display: none;
        @include mq-down(md) {
            font-weight: normal;
            width: 100%;
            margin-top: 20px;
            height: 60px;
            display: flex;
            align-items: center;
        }
    }
    .single-bottom-banner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mq-down(md) {
            margin-top: 20px;
        }
        & > * {
            width: 49%;
            @include mq-down(md) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}
