.entry-header {
	margin-bottom: 50px;
	@include mq-down(md) {
		margin-bottom: 30px;
	}
}
.entry-content {
	margin-bottom: 50px;
	@include mq-down(md) {
		margin-bottom: 30px;
	}
}
.entry-footer {
	margin-bottom: 50px;
	@include mq-down(md) {
		margin-bottom: 30px;
	}
}
