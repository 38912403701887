/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

img {
	width: 100%;
	height: auto;
}

figure {
	margin: 1em 0; // Extra wide images within figure tags don't overflow the content area.
}

video {
	width: 100%;
	height: auto;
}
